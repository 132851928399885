let Socket = null
let connectUrl = ''
let setIntervalWesocketPush = null
let reCount = 0
let isEnableReconnect = true //是否允许重连标志

/**
 * 建立websocket连接
 * @param {string} url ws地址
 */
export const createSocket = url => {
  console.log('websocket准备连接')
  connectUrl = url
  disconnectSocket()
  Socket = null
  if (!Socket) {
    console.log('建立websocket连接:' + url)
    isEnableReconnect = true
    Socket = new WebSocket(url)
    Socket.onopen = onopenWS
    Socket.onmessage = onmessageWS
    Socket.onerror = onerrorWS
    Socket.onclose = oncloseWS
  } else {
    console.log('websocket已连接')
  }
}
export const disconnectSocket = () => {
    Socket && Socket.close('1000','主动断开')
  }
/**打开WS之后发送心跳 */
const onopenWS = () => {
    console.log('websocket连接成功')
    sendPing()
    window.dispatchEvent(new CustomEvent('onConnectedWS', {
    }))
}

/**连接失败重连 */
const onerrorWS = () => {
  Socket.close()
  clearInterval(setIntervalWesocketPush)
  console.log('连接失败重连中')
  if (Socket.readyState !== 3) {
    Socket = null
    createSocket(connectUrl)
  }
}

/**WS数据接收统一处理 */
const onmessageWS = e => {

  const data = e.data;
  let object = JSON.parse(data);
  if (object.messageType == "squeezeTheLine" 
  || object.messageType == "tokenExpiredEvent" 
  || object.messageType == "finishedEvent" 
  || object.messageType == "paramErrorEvent" 
  || object.messageType == "userNotExistsEvent") {
    isEnableReconnect = false
  } 
  window.dispatchEvent(new CustomEvent('onmessageWS', {
    detail: {
      data: e.data
    }
  }))
}

/**断开重连 */
const oncloseWS = (p,e) => {
  console.log('websocket断开',p)
  if(setIntervalWesocketPush){
    clearInterval(setIntervalWesocketPush)
  }
  //客户端主动断开 服务器主动断开
  if(p && (p.code == 1000 || !isEnableReconnect)){
      console.log("websocket已断开....")
  }else{
      if (Socket.readyState !== 2) {
      Socket = null
      reconnectSocket()
    }
  }
}

/**
 * 发送数据但连接未建立时进行处理等待重发
 * @param {any} message 需要发送的数据
 */
const connecting = message => {
  setTimeout(() => {
    if (Socket.readyState === 0) {
      connecting(message)
    } else {
      Socket.send(JSON.stringify(message))
    }
  }, 1000)
}

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
export const sendWSPush = message => {
  if (Socket !== null && Socket.readyState === 3) {
    Socket.close()
    Socket = null
    createSocket(connectUrl)
  } else if (Socket.readyState === 1) {
    let msg = JSON.stringify(message)
    console.log('websocket发送:' + msg)
    Socket.send(msg)
    
  } else if (Socket.readyState === 0) {
    connecting(message)
  }
}

function reconnectSocket(){
  reCount++
  if(reCount == 3){
    reCount = 0
    return
  }
  console.log('websocket已断开....正在尝试重连')
  createSocket(connectUrl)
}

/**发送心跳
 * @param {number} time 心跳间隔毫秒 默认5000
 * @param {string} ping 心跳名称 默认字符串ping
 */
export const sendPing = () => {
  if(setIntervalWesocketPush){
    clearInterval(setIntervalWesocketPush)
  }
  setIntervalWesocketPush = setInterval(() => {
    console.log('websocket 发送心跳')
    Socket.send(JSON.stringify({messageType:'ping'}))
  }, 5000)
  
}