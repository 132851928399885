<template>
  <div class="default-max">
    <div style="
                          background-color: rgb(77, 77, 77);
                          height: 100%;
                          width: 100%;
                          border-radius: 8px;
                          overflow: hidden;
                          display: flex;
                          flex-direction: column;
                        ">
      <div class="container">
        <template v-if="isBoarder">
          <!-- 只有一个 -->
          <div :class="[!isMulitScreen ? 'main-screen-big' : 'main-screen-small']" style="position: relative"
            id="leftNode">
            <video ref="mainScreen" width="100%" height="100%" style="background-color: #484848; object-fit: cover"
              autoplay :muted="true"></video>
            <div v-if="isBoarder && !cameraTrack && !localCustomTracks" style="
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                  justify-content: center;
                                  position: absolute;
                                  top: 0;
                                  left: 0;
                                  right: 0;
                                  bottom: 0;
                                ">
              <img src="/images/icon_wait.png" />
              <div style="color: #ffffff; margin-top: 40px">{{ errorDes }}</div>
            </div>
            <div v-else-if="
              isBoarder &&
              cameraTrack &&
              cameraTrack.isLocalMuted() &&
              !localCustomTracks
            " style="
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                  justify-content: center;
                                  position: absolute;
                                  top: 0;
                                  left: 0;
                                  right: 0;
                                  bottom: 0;
                                ">
              <img src="/images/icon_wait.png" />
              <div style="color: #ffffff; margin-top: 40px">摄像头已关闭</div>
            </div>
          </div>
          <!-- 存在多个流 -->
          <div id="rightNode" :class="[isMulitScreen ? 'right-screen' : '']"></div>
        </template>
        <template v-else>
          <div class="main-screen-big" style="background-color: red; flex: 1">
            <video ref="mainScreen" width="100%" height="100%" style="background-color: #484848; object-fit: cover"
              autoplay :muted="true"></video>
            <div v-if="isBoarder == false && remoteTracks.length == 0" style="
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                  justify-content: center;
                                  position: absolute;
                                  top: 0;
                                  left: 0;
                                  right: 0;
                                  bottom: 0;
                                ">
              <img src="/images/icon_wait.png" />
              <div style="color: #ffffff; margin-top: 40px">等待主播进入</div>
            </div>
          </div>
        </template>
      </div>
      <a-row type="flex" justify="space-around" style="margin-top: 6px; height: 92px; background-color: rgb(56, 56, 56)">
        <template v-if="isBoarder">
          <a-col v-if="microphones.length > 0 || cameras.length > 0" @click="deviceAction" style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              ">
            <img :src="'/images/out_device.png'" />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              输入源
            </div>
          </a-col>
          <a-col v-if="microphoneTrack" style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="microphoneAction">
            <img :src="
              microphoneTrack && microphoneTrack.isLocalMuted()
                ? '/images/icon_device_mic_close.png'
                : '/images/icon_device_mic.png'
            " />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              {{
                microphoneTrack && microphoneTrack.isLocalMuted()
                ? "麦克风已关闭"
                : "麦克风已打开"
              }}
            </div>
          </a-col>
          <a-col v-if="cameraTrack" style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="cameraAction">
            <img :src="
              cameraTrack && cameraTrack.isLocalMuted()
                ? '/images/icon_device_camera_close.png'
                : '/images/icon_device_camera.png'
            " />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              {{
                cameraTrack && cameraTrack.isLocalMuted()
                ? "摄像头已关闭"
                : "摄像头已打开"
              }}
            </div>
          </a-col>
          <a-col style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="getShareScreen">
            <img :src="
              isShareing
                ? '/images/icon_share_screen_close.png'
                : '/images/icon_share_screen.png'
            " />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              {{ isShareing ? "取消共享屏幕" : "共享屏幕" }}
            </div>
          </a-col>
          <a-col v-if="isShareVideo" style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="unpublishCustomVideo">
            <img src="/images/icon_share_media_close.png" />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              取消共享音视频
            </div>
          </a-col>
          <a-col v-else style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              ">
            <el-upload ref="upload" action="https://" accept="audio/*,video/*" :limit="1" :auto-upload="false"
              :show-file-list="false" :before-upload="before_upload" :on-error="onErrorAction"
              :on-change="createCustomMedia">
              <a-col slot="trigger" style="
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                  ">
                <img src="/images/icon_share_media_open.png" />

                <div style="
                                      font-size: 12px;
                                      color: #ffffff;
                                      font-weight: 400;
                                      margin-top: 3px;
                                    ">
                  共享音视频
                </div>
              </a-col>
            </el-upload>
          </a-col>
          <a-col v-if="isOwner" style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="manageAction">
            <img src="/images/icon_member_manage.png" />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              连麦管理
            </div>
          </a-col>
          <a-col v-else style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="manageAction">
            <img src="/images/icon_member_manage.png" />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              互动人员
            </div>
          </a-col>
          <a-col v-if="!isOwner" style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="adjectiveEndMic">
            <img src="/images/icon_cancle_mic.png" />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              结束连麦
            </div>
          </a-col>
        </template>
        <template v-else>
          <a-col style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="unmuteAction">
            <img :src="
              muted
                ? '/images/icon_sound_close.png'
                : '/images/icon_sound_open.png'
            " />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              {{ muted ? "扬声器已关闭" : "扬声器已打开" }}
            </div>
          </a-col>
          <a-col v-if="isMobile ? false : true" style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="applyMicAction">
            <img :src="
              isApply
                ? '/images/icon_cancle_mic.png'
                : '/images/icon_apply_micro.png'
            " />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              {{ isApply ? "取消申请" : "申请连麦" }}
            </div>
          </a-col>
          <a-col v-if="isMobile ? false : true" style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                              " @click="manageAction">
            <img src="/images/icon_member_manage.png" />
            <div style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 400;
                                  margin-top: 3px;
                                ">
              互动人员
            </div>
          </a-col>
        </template>
        <a-col v-if="isMobile ? false : true" style="
                              display: flex;
                              flex-direction: column;
                              justify-content: center;
                              align-items: center;
                            " @click="imShowAction">
          <img src="/images/icon_chat.png" />
          <div style="
                                font-size: 12px;
                                color: #ffffff;
                                font-weight: 400;
                                margin-top: 3px;
                              ">
            消息
          </div>
        </a-col>
        <a-col v-if="isMobile ? false : true" style="display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;521827
                                      " @click="alertLiveRoom">
          <img src="/images/icon_leave_room.png" />
          <div style="
                                font-size: 12px;
                                color: #ffffff;
                                font-weight: 400;
                                margin-top: 3px;
                              ">
            退出直播
          </div>
        </a-col>
      </a-row>
    </div>
    <el-drawer :title="isOwner ? '连麦管理' : '互动人员'" :visible.sync="drawer" direction="rtl">
      <el-tabs v-if="isOwner" v-model="activeName" @tab-click="handleClick" style="margin: 0px 20px" v-loading="loading">
        <el-tab-pane label="   连麦申请" name="first"></el-tab-pane>
        <el-tab-pane label="邀请/管理连麦" name="second"></el-tab-pane>
      </el-tabs>
      <el-empty v-if="datas.length == 0 ? true : false" description="暂无人员"></el-empty>
      <div v-for="(item, index) in datas" :key="index" class="member-row">
        <img :src="item.avatar" alt="" class="avatar" />
        <div style="color: #484848; flex: 1; text-align: left">
          {{ item.userName }}
        </div>
        <template v-if="isOwner">
          <div v-if="activeName == 'first' ? true : false" style="margin: auto 15px">
            <el-button type="primary" size="mini" @click="agreeMIC(item.userId)">同意</el-button>
            <el-button type="danger" size="mini" @click="refuseMIC(item.userId)">拒绝</el-button>
          </div>
          <div v-else style="margin: auto 15px">
            <el-button v-if="item.applyMICStatus == 0 ? true : false" type="primary" size="mini"
              @click="inviteJoin(item)">邀请加入</el-button>
            <el-button v-else-if="item.applyMICStatus == 2 ? true : false" type="primary" size="mini"
              disabled>已邀请</el-button>
            <el-button v-else-if="item.applyMICStatus == 3 ? true : false" type="danger" size="mini"
              @click="endMic(item)">停止连麦</el-button>
          </div>
        </template>
        <template v-else>
          <div style="margin: auto 15px">
            <el-button v-if="item.speaker" type="primary" size="mini">主讲</el-button>
            <el-button v-else-if="item.applyMICStatus == 3 ? true : false" type="danger" size="mini">连麦中</el-button>
          </div>
        </template>
      </div>
    </el-drawer>
    <el-dialog title="异常" :show-close="true" :visible.sync="dialog" width="30%">
      <div class="demo-drawer__content">
        <ul style="overflow: auto">
          <div>当前账号已在其他端登录?</div>
        </ul>
        <span slot="footer" class="dialog-footer" style="margin-top: 30px">
          <el-button type="primary" round @click="exceptLoginOut" style="width: 320px; height: 50px">确 定</el-button>
        </span>
      </div>
    </el-dialog>
    <el-drawer title="聊天室" :visible.sync="imDrawer" direction="rtl" :destroy-on-close="true" :modal="false">
      <el-empty v-if="msgList.length == 0 ? true : false" description="暂无消息"></el-empty>
      <div style="margin-bottom: 180px; overflow-y: scroll; height: 100%" ref="scrollDiv">
        <div v-for="(item, index) in msgList" :key="index" class="msg-content">
          <span style="color: #0090d7; max-width: 60px">{{
            `${fetchName(item.content.extra)}`
          }}</span>
          {{
            item.messageType == "RC:TxtMsg"
            ? item.content.content
            : "暂不支持该消息类型"
          }}
        </div>
      </div>

      <div class="chat-massage-editor">
        <div class="chat-editor">
          <div style="width: 100%; height: 0.5px; background-color: #f3f3f3"></div>
          <el-input placeholder="请输入内容，按下Enter键发送" type="textarea" rows="3" v-model="inputMsg" maxlength="500"
            resize="none" @input="inputAction" @keyup.enter.native="checkSenorText" style="width: 98%; margin-top: 5px">
          </el-input>
          <el-button :disabled="sendDisable" type="primary" @click="checkSenorText"
            style="width: 60%; margin-top: 20px">发送</el-button>
        </div>
      </div>
    </el-drawer>
    <el-drawer title="设备管理" :visible.sync="deviceDrawer" direction="ltr" :destroy-on-close="true" :modal="false">
      <div style="display: flex; flex-direction: column; align-items: center">
        <div style="
                              display: flex;
                              flex-direction: row;
                              justify-content: center;
                              align-items: center;
                            ">
          <div style="margin-right: 10px">摄像头</div>
          <el-select v-model="cameraId" placeholder="请选择">
            <el-option v-for="item in cameras" :key="item.label" :label="item.label" :value="item.deviceId">
            </el-option>
          </el-select>
        </div>
        <div style="
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              margin-top: 20px;
                            ">
          <div style="margin-right: 10px">麦克风</div>
          <el-select v-model="microphoneId" placeholder="请选择">
            <el-option v-for="item in microphones" :key="item.label" :label="item.label" :value="item.deviceId">
            </el-option>
          </el-select>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  installer,
  RCRTCCode,
  RCLocalTrack,
  RCLivingType,
  device,
} from "@rongcloud/plugin-rtc";
// imkit 为核心模块
import * as RongIMLib from "@rongcloud/imlib-next";
import element from "../../ElementUI.vue";
import {
  createSocket,
  sendWSPush,
  disconnectSocket,
} from "../../utils/websocket.js";

export default {
  data() {
    return {
      imRoomId: "",
      sendDisable: true,
      inputMsg: "",
      msgList: [],
      imDrawer: false,
      deviceDrawer: false,
      isApply: false,
      datas: [],
      loading: false,
      activeName: "first",
      drawer: false,
      mainScreenBig: "main-screen-big",
      mainScreenSmall: "main-screen-small",
      isShareing: false,
      isShareVideo: false,
      appkey: "tdrvipkstvhf5",
      token: this.$store.getters.ryToken,
      remoteTracks: [],
      remoteTrackIds: [],
      userId: "",
      roomId: "", //0311203936583
      classRoomId: "",
      mediaTag: "",
      room: null,
      muted: true,
      isMobile: false,
      microphoneTrack: null,
      cameraTrack: null,
      localCustomTracks: null,
      screenShareTrack: null,
      myUid: null,
      errorDes: "",
      isBoarder: false,
      websocket: null,
      timer: null,
      dialog: false,
      isMulitScreen: false,
      cameras: [],
      microphones: [],
      cameraId: "",
      microphoneId: "",
      inputDeviceList: [],//输入设备列表
    };
  },
  computed: {
    isOwner() {
      if (this.userId === this.myUid) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    remoteTracks(newVal, val) {
      this.isMulitScreenCalc();
      this.updateLayoutUI();
    },
    localCustomTracks(newVal, val) {
      this.isMulitScreenCalc();
      this.updateLayoutUI();
    },
    screenShareTrack(newVal, val) {
      this.isMulitScreenCalc();
      this.updateLayoutUI();
    },
    cameraId(newVal, val) {
      if (this.isBoarder) {
        this.publishCameraVideo();
      }
    },
    microphoneId(newVal, val) {
      if (this.isBoarder) {
        this.publishMicrophone();
      }
    },
  },
  created() { },
  mounted() {
    this.roomId = this.$route.query.roomNumber;
    this.classRoomId = this.$route.query.roomId;
    // this.imRoomId = this.$route.query.imRoomId;
    this.myUid = this.$route.query.myUid; //兼容小程序
    this.userId = this.$route.query.userId;
    if (this.userId === this.myUid) {
      this.isBoarder = true;
    }
    console.log("当前身份", this.isBoarder, this.isMobile);
    this.userId = this.$route.query.userId;
    console.log("当前身份1111");
    this.createWebSocket();
    this.initIMSDK();
    //外设变化
    navigator.mediaDevices.addEventListener('devicechange', (event) => {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          this.cameras = devices.filter(device => device.kind == "videoinput");
          this.microphones = devices.filter(device => device.kind == "audioinput");
          let currentCameraDevice = this.cameras.filter(device => device.deviceId == this.cameraId)
          console.log('当前摄像头', currentCameraDevice, devices)
          //当前播放的摄像头被移除
          if (currentCameraDevice.length == 0) {
            if (this.cameras && this.cameras.length != 0) {
              this.cameraId = this.cameras[0].deviceId;
              console.log("重新切换摄像头")
            }
          } else {
            console.log("不需要重新切换摄像头")
          }
          //当前麦克风被移除
          let currentAudioDevice = this.microphones.filter(device => device.deviceId == this.microphoneId)
          //当前播放的摄像头被移除
          if (currentAudioDevice.length == 0) {
            if (this.microphones && this.microphones.length != 0) {
              this.microphoneId = this.microphones[0].deviceId;
            }
          }
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
    })
  },

  beforeDestroy() {
    console.log('准备释放资源')
    window.removeEventListener("onmessageWS", this.getsocketData);
    window.removeEventListener("onConnectedWS", this.onConnectedWS);
    navigator.mediaDevices.removeEventListener('devicechange')
    RongIMLib.clearEventListeners();
    if (RongIMLib.getConnectionStatus() == 0) {
      this.getOutLiveRoom();
    }
  },

  methods: {
    deviceAction() {
      this.deviceDrawer = true;
    },
    fetchName(extra) {
      if (extra) {
        let item = JSON.parse(extra);
        return `${item.name ? item.name : "xxxx"}：`;
      }
      return "";
    },
    imShowAction() {
      this.imDrawer = true;
      this.handleScrollBottom();
    },
    // 滚动到底部
    handleScrollBottom() {
      this.$nextTick(() => {
        let scrollElem = this.$refs.scrollDiv;
        console.log("马上刷新", scrollElem.scrollHeight);
        scrollElem.scrollTo({
          top: scrollElem.scrollHeight,
          behavior: "smooth",
        });
      });
    },
    inputAction(value) {
      if (value && value.length != 0) {
        this.sendDisable = false;
      } else {
        this.sendDisable = true;
      }
    },
    //检查敏感词
    checkSenorText() {
      let that = this;
      // this.datas = [];
      this.$HTTP
        .httpToken({
          url: "/classroom/keyword/checkKeyword",
          method: "post",
          data: {
            text: this.inputMsg,
          },
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            that.sendTextMsg(res.data.text);
          } else {
            this.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "error",
              duration: 3000,
            });
          }
        })
        .catch((e) => { });
    },
    // 检查是否可以申请连麦
    agreeJoinLiveAction() {
      let that = this;
      this.$HTTP
        .httpToken({
          url: "/classroom/classRoomMember/getConnectWheatCount",
          method: "post",
          data: {
            roomId: this.roomId,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            sendWSPush({
              messageType: "agreeInvitationMIC",
              fromUserId: that.myUid,
              toUserId: that.userId,
              userName: that.$store.getters.userName,
            });
            that.switchToBoarder();
          } else {
            that.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "error",
              duration: 3000,
            });
          }
        })
        .catch((e) => { });
    },
    sendTextMsg(text) {
      const conversation = {
        conversationType: RongIMLib.ConversationType.CHATROOM,
        targetId: this.roomId,
      };
      // 实例化待发送消息，RongIMLib.TextMessage 为内置文本型消息
      const message = new RongIMLib.TextMessage({
        // 文本内容
        content: text,
        // （可选）消息中附加信息，透传到对端
        extra: JSON.stringify({
          name: this.$store.state.userInfo.name
            ? this.$store.state.userInfo.name
            : this.$store.state.userInfo.realname,
          avatar: this.$store.state.userInfo.avatar,
        }),
      });
      let that = this;
      // 配置属性
      const options = {
        // 如果需要发送 @ 消息，isMentioned 需设置为 true
        isMentioned: true,
        // 消息发送前的回调，可以使用此回调返回的 message 用于列表渲染
        onSendBefore: (message) => {
          console.log("消息发送前的回调", message);
          that.msgList.push(message);
          that.handleScrollBottom();
          that.inputMsg = "";
        },
      };
      RongIMLib.sendMessage(conversation, message, options).then((res) => {
        if (res.code === RongIMLib.ErrorCode.SUCCESS) {
          console.log("消息发送成功", that.msgList);
        } else {
          console.log("消息发送失败", res.code, res.msg);
          if (res.code == 23406) {
            this.reconnectIM();
            this.$elementMessage({
              showClose: false,
              message: "正在重新进入聊天室，稍后重试...",
              type: "error",
              duration: 3000,
            });
          } else if (res.code == 23408) {
            this.$elementMessage({
              showClose: false,
              message: "你在聊天室中被禁言",
              type: "error",
              duration: 3000,
            });
          } else if (res.code == 23409) {
            this.$elementMessage({
              showClose: false,
              message: "你被踢出并禁止加入聊天室",
              type: "error",
              duration: 3000,
            });
          }
        }
      });
    },
    initIMSDK() {
      let that = this;
      let libOption = { appkey: this.appkey };
      RongIMLib.init(libOption);
      const Events = RongIMLib.Events;
      RongIMLib.addEventListener(Events.MESSAGES, (event) => {
        console.log("融云收到消息", event.messages);
        if (event && event.messages) {
          that.msgList.push(...event.messages);
          that.handleScrollBottom();
        }
      });
      RongIMLib.addEventListener(Events.CONNECTING, () => {
        console.log("融云IMonConnecting");
      });
      RongIMLib.addEventListener(Events.CONNECTED, () => {
        console.log("融云IMonConnected");
        that.joinIMRoomAction();
      });
      RongIMLib.addEventListener(Events.DISCONNECT, (status) => {
        console.log("融云IM连接中断，需要业务层进行重连处理 ->", status);
      });
      this.connectIM();
    },

    //IM连接
    connectIM() {
      if (RongIMLib.getConnectionStatus() == 0) {
        RongIMLib.disconnect();
      }
      let that = this;
      RongIMLib.connect(this.token)
        .then((user) => {
          console.log("融云IM链接成功，链接用户id为：", user.data.userId);
          that.rtcClient = RongIMLib.installPlugin(installer, {
            /*初始化参数请参考下方参数说明*/
          });
          console.log(that.rtcClient);
          //主播
          if (that.userId === that.myUid) {
            that.joinLivingRoom();
          } else {
            that.audienceJoin();
          }
        })
        .catch((error) => {
          console.log("链接失败：", error.code, error.msg);
        });
    },
    // 融云IM重新链接
    reconnectIM() {
      let that = this;
      RongIMLib.connect(this.token)
        .then((user) => {
          console.log("融云IM重新链接成功，链接用户id为：", user.data.userId);
          console.log(that.rtcClient);
        })
        .catch((error) => {
          console.log("链接失败：", error.code, error.msg);
        });
    },
    //加入聊天室
    joinIMRoomAction() {
      console.log("加入IM聊天室的Id", this.roomId);
      RongIMLib.joinChatRoom(this.roomId, {
        count: 10,
      })
        .then((res) => {
          // 加入聊天室成功
          if (res.code == 0) {
            console.log("融云IM加入聊天室成功", res);
          } else {
            console.log("融云IM加入聊天室失败", res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getIMRoomHistoryAction() {
      const chatRoomId = this.roomId;
      const timestamp = 0;
      const count = 10;
      const order = 1;
      RongIMLib.getChatroomHistoryMessages(chatRoomId, {
        timestamp: timestamp,
        count: count,
        order: order,
      })
        .then((res) => {
          // 获取聊天室历史信息成功
          console.log("获取消息");
          if (res.code === 0) {
            console.log(res.code, res.data);
          } else {
            console.log(res.code, res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //是否需要多屏展示计算this.screenShareTrack || (this.localCustomTracks && this.localCustomTracks.length != 0) ||
    isMulitScreenCalc() {
      if (this.screenShareTrack || this.remoteTracks.length != 0) {
        this.isMulitScreen = true;
      } else {
        this.isMulitScreen = false;
      }
    },
    exceptLoginOut() {
      this.getOutLiveRoom();
      this.$HTTP.loginOutStore();
    },
    //切换到主播模式
    async switchToBoarder() {
      //移除监听
      this.room.registerRoomEventListener(null);
      //取消订阅的合流
      let result = await this.room.unsubscribe(this.remoteTracks);
      this.remoteTracks = [];
      const { room, code, tracks } = await this.rtcClient.upgradeToAnchorRoom(
        this.room
      );
      this.isBoarder = true;
      console.log("成为主播");
      this.registAnchorEventListener(room, code, tracks);
    },
    //切换到观众模式
    async switchToAudience() {
      console.log("即将切换到观众");
      //移除监听
      this.room.registerRoomEventListener(null);
      //取消发布 销毁直播流
      if (this.cameraTrack) {
        let result = await this.room.unpublish([this.cameraTrack]);
        if (result.code == RCRTCCode.SUCCESS) {
          console.log("取消视频发布成功");
        }
        this.cameraTrack.destroy();
        this.cameraTrack = null;
      }
      if (this.microphoneTrack) {
        let result = await this.room.unpublish([this.microphoneTrack]);
        if (result.code == RCRTCCode.SUCCESS) {
          console.log("取消麦克风发布成功");
        }
        this.microphoneTrack.destroy();
        this.microphoneTrack = null;
      }
      if (this.screenShareTrack) {
        this.room.unpublish([this.screenShareTrack]);
        this.screenShareTrack.destroy();
        this.screenShareTrack = null;
        this.isShareing = false;
      }
      if (this.localCustomTracks && this.localCustomTracks.length != 0) {
        this.room.unpublish(this.localCustomTracks);
        this.localCustomTracks = null;
        this.isShareVideo = false;
      }
      this.unsubscribe(this.remoteTracks, false);
      this.remoteTracks = [];
      console.log(this.room);
      console.log(this.rtcClient);
      const { room, code, MCUTracks } =
        await this.rtcClient.downgradeToAudienceRoom(this.room);
      this.isBoarder = false;
      this.registAudienceEventListener(room, code, MCUTracks);
    },
    //创建websocket
    createWebSocket() {
      let troomId = this.$route.query.roomId;
      let tclassRoomId = this.$route.query.classRoomId;
      let troomNumber = this.$route.query.roomNumber;
      createSocket(
        "wss://swlmprod.skillhub.top/classroom/websocket/live/" +
        this.myUid +
        "/" +
        tclassRoomId +
        "/" +
        troomId +
        `?token=${this.$store.getters.token}`
      );
      window.addEventListener("onmessageWS", this.getsocketData);
      window.addEventListener("onConnectedWS", this.onConnectedWS);
    },
    onConnectedWS() {
      console.log("websocket 连接成功，马上开始进行融云IM初始化");
      // this.initIMSDK();
    },
    getsocketData(e) {
      const data = e && e.detail.data;
      let object = JSON.parse(data);
      console.log("websocket收到消息：" + data);
      if (object.messageType == "applyMIC") {
        this.$elementMessage({
          showClose: false,
          message: object.userName + "申请连麦",
          type: "success",
        });
        this.requestMemberList();
      } else if (object.messageType == "pushMemberCountAndList") {
        console.log("更新数据");
        this.requestMemberList();
      } else if (object.messageType == "endMIC") {
        console.log("更新数据");
        this.requestMemberList();
      } else if (object.messageType == "refuseMIC") {
        this.requestMemberList();
        this.$elementMessage({
          showClose: false,
          message: "主播拒绝了你的连麦申请",
          type: "error",
        });
        this.isApply = false;
      } else if (object.messageType == "agreeMIC") {
        this.requestMemberList();
        this.$elementMessage({
          showClose: false,
          message: "主播同意你的连麦申请",
          type: "success",
        });
        this.isApply = false;
        this.switchToBoarder();
        //
      } else if (object.messageType == "cancelMIC") {
        this.requestMemberList();
      } else if (object.messageType == "liverForceMIC") {
        this.$elementMessage({
          showClose: false,
          message: "主播暂停了您的连麦",
          type: "error",
        });
        this.isApply = false;
        this.switchToAudience();
      } else if (object.messageType == "endLive") {
        //主持人结束会议
        this.$elementMessage({
          showClose: false,
          message: "主播结束了会议",
          type: "error",
        });
        this.getOutLiveRoom();
      } else if (object.messageType == "invitationMIC") {
        //主持人邀请加入会议
        this.alertInviteAction("主播" + object.userName + "邀请你加入会议");
      } else if (object.messageType == "refuseInvitationMIC") {
        this.$elementMessage({
          showClose: false,
          message: object.userName + "拒绝连麦邀请",
          type: "error",
        });
        this.requestMemberList();
      } else if (object.messageType == "agreeInvitationMIC") {
        this.$elementMessage({
          showClose: false,
          message: object.userName + "同意了连麦邀请",
          type: "success",
        });
        this.requestMemberList();
      } else if (object.messageType == "squeezeTheLine") {
        this.$elementMessage({
          showClose: false,
          message: "已在其他终端登录",
          type: "success",
        });
        this.exceptLoginOut();
      } else if (object.messageType == "tokenExpiredEvent") {
        this.$elementMessage({
          showClose: false,
          message: "登录已过期，请重新登录",
          type: "error",
        });
        this.exceptLoginOut();
      } else if (object.messageType == "finishedEvent") {
        this.$elementMessage({
          showClose: false,
          message: "会议已结束",
          type: "error",
        });
        this.getOutLiveRoom();
      }
    },
    //申请连麦
    applyMicAction() {
      this.isApply = !this.isApply;
      if (this.isApply) {
        this.applyJoinLiveAction();
      } else {
        sendWSPush({
          messageType: "cancelMIC",
          fromUserId: this.myUid,
          toUserId: this.userId,
          userName: this.$store.getters.userName,
        });
      }
    },
    // 检查是否可以申请连麦
    applyJoinLiveAction() {
      let that = this;
      this.$HTTP
        .httpToken({
          url: "/classroom/classRoomMember/getConnectWheatCount",
          method: "post",
          data: {
            roomId: this.roomId,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            sendWSPush({
              messageType: "applyMIC",
              fromUserId: that.myUid,
              toUserId: that.userId,
              userName: that.$store.getters.userName,
            });
          } else {
            that.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "error",
              duration: 3000,
            });
          }
        })
        .catch((e) => { });
    },
    //主播邀请提示
    alertInviteAction(msg) {
      let that = this;
      this.$elementConfirm(msg, "提示", {
        confirmButtonText: "接受",
        cancelButtonText: "拒绝",
        type: "warning",
        roundButton: true,
        center: true,
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
        callback: this.receiveOrRefuseAction,
      });
    },
    receiveOrRefuseAction(action, instance) {
      if (action == "cancel") {
        sendWSPush({
          messageType: "refuseInvitationMIC",
          fromUserId: this.myUid,
          toUserId: this.userId,
          userName: this.$store.getters.userName,
        });
      } else if (action == "confirm") {
        this.agreeJoinLiveAction();
      }
    },
    //主播离开房间提示
    alertLiveRoom() {
      if (this.isBoarder && this.isOwner) {
        let that = this;
        this.$elementConfirm("是否结束当前会议?", "提示", {
          confirmButtonText: "结束会议",
          cancelButtonText: "仅离开会议",
          type: "warning",
          roundButton: true,
          center: true,
          closeOnClickModal: false,
          distinguishCancelAndClose: true,
          callback: this.alertAction,
        });
      } else {
        this.getOutLiveRoom();
      }
    },
    alertAction(action, instance) {
      if (action == "cancel") {
        this.getOutLiveRoom();
      } else if (action == "confirm") {
        sendWSPush({
          messageType: "endLive",
          fromUserId: this.myUid,
          roomId: this.$route.query.roomId,
        });
        this.getOutLiveRoom();
      }
    },
    //临时主播结束连麦
    adjectiveEndMic(item) {
      sendWSPush({
        messageType: "endMIC",
        fromUserId: this.myUid,
        toUserId: item.userId,
      });
      item.applyMICStatus = 0;
      this.switchToAudience();
    },
    //主播邀请连麦
    inviteJoin(item) {
      sendWSPush({
        messageType: "invitationMIC",
        fromUserId: this.myUid,
        toUserId: item.userId,
        userName: this.$store.getters.userName,
      });
      item.applyMICStatus = 2;
    },
    //主播结束连麦
    endMic(item) {
      sendWSPush({
        messageType: "liverForceMIC",
        fromUserId: this.myUid,
        toUserId: item.userId,
      });
      item.applyMICStatus = 0;
    },
    //主播同意连麦申请
    agreeMIC(uid) {
      sendWSPush({
        messageType: "agreeMIC",
        fromUserId: this.myUid,
        toUserId: uid,
      });
      console.log("准备更新列表");
      let index = this.datas.findIndex((item) => {
        return item.userId == uid;
      });
      this.datas.splice(index, 1);
      // this.requestMemberList();
    },
    //主播拒绝连麦申请
    refuseMIC(uid) {
      sendWSPush({
        messageType: "refuseMIC",
        fromUserId: this.myUid,
        toUserId: uid,
      });
      let index = this.datas.findIndex((item) => {
        return item.userId == uid;
      });
      this.datas.splice(index, 1);
      // this.requestMemberList();
    },
    //获取人员列表
    requestMemberList() {
      if (!this.drawer) {
        return;
      }
      console.log("正在更新列表");
      let that = this;
      this.datas = [];
      this.loading = true;
      this.$HTTP
        .httpToken({
          url: "/classroom/classRoomMember/getCloudClassRoomMemberList",
          method: "post",
          data: {
            roomId: this.classRoomId,
            isLive: true,
            applyMICStatus: this.isOwner
              ? this.activeName == "first"
                ? 1
                : 2
              : -1,
          },
        })
        .then((res) => {
          that.loading = false;
          if (res.code == 0) {
            that.datas = res.data.memberList;
            console.log(that.datas);
          } else if (res.code == 8002) {
            that.dialog = true;
          }
        })
        .catch((e) => {
          hat.loading = false;
          console.log(e);
        });
    },
    //切换人员管理
    handleClick(tab, event) {
      console.log(tab, event);
      this.requestMemberList();
    },
    //连麦管理
    manageAction() {
      this.drawer = !this.drawer;
      this.requestMemberList();
    },
    unmuteAction() {
      this.muted = !this.muted;
      if (this.muted) {
        this.remoteTracks.forEach((item) => {
          if (item.isAudioTrack()) {
            item.mute();
          }
        });
      } else {
        this.remoteTracks.forEach((item) => {
          if (item.isAudioTrack()) {
            item.unmute();
            item.play();
          }
        });
      }
      console.log("121313113");
    },
    //主播加入房间流程
    async joinLivingRoom() {
      let roomId = this.roomId;
      const { code, room, tracks } = await this.rtcClient.joinLivingRoom(
        roomId,
        RCLivingType.VIDEO
      );
      // 若加入失败，则 room、userIds、tracks 值为 undefined
      this.registAnchorEventListener(room, code, tracks);
    },
    onErrorAction(file) {
      console.log("00000000");
    },
    before_upload(file) {
      console.log("000011111");

      this.createCustomMedia(file);
    },
    //1、创建自定义流 共享音视频
    async createCustomMedia(file) {
      console.log("1111111", file);
      if (this.isShareing) {
        this.$elementAlert("当前正在共享屏幕，无法同时共享音视频", "温馨提示", {
          confirmButtonText: "确定",
          callback: (action) => { },
        });
        console.log(this.$refs.upload);
        this.$refs.upload.abort();
        this.$refs.upload.clearFiles();
        return;
      }
      console.warn("准备自定义 ->");
      const { code, tracks } = await this.rtcClient.createLocalFileTracks(
        "customMediaFile",
        file.raw
      );
      if (code !== RCRTCCode.SUCCESS) {
        console.warn("获取自定义流失败 ->", code);
        if (code == 53019) {
          this.$elementMessage({
            showClose: false,
            message: "当前浏览器不支持共享音视频,建议使用Chrome浏览器",
            type: "error",
          });
        } else {
          this.$elementMessage({
            showClose: false,
            message: "获取音视频异常，请重新尝试",
            type: "error",
          });
        }
        return;
      }
      console.warn("获取自定义流成功 ->", tracks);
      this.publishCustomMedia(tracks);
    },
    //2、发布发布自定义流
    async publishCustomMedia(tracks) {
      if (tracks.length == 0) {
        return;
      }
      const { code } = await this.room.publish(tracks);
      if (code !== RCRTCCode.SUCCESS) {
        console.warn("发布自定义流失败 ->", code);
        this.$elementMessage({
          showClose: false,
          message: "共享音视频异常，请重新尝试",
          type: "error",
        });
        return;
      }
      console.warn("发布自定义流成功 ->", code);
      this.localCustomTracks = tracks;
      this.isShareVideo = true;
    },
    // 3、取消发布自定义流
    async unpublishCustomVideo() {
      if (this.localCustomTracks && this.localCustomTracks.length != 0) {
        console.log("取消自定义流");
        console.log(this.localCustomTracks);
        const { code } = await this.room.unpublish(this.localCustomTracks);
        if (code !== RCRTCCode.SUCCESS) {
          console.warn("取消自定义流失败 ->", code);
          return;
        }
        this.localCustomTracks.forEach((track) => {
          track.destroy();
        });

        console.warn("取消自定义流成功 ->", code);
        this.localCustomTracks = null;
        this.isShareVideo = false;
      }
    },
    checkMicrophones() {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          /* use the stream */
          navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
              this.microphones = devices.filter(device => device.kind == "audioinput");
              if (this.microphones && this.microphones.length != 0) {
                this.microphoneId = this.microphones[0].deviceId;
              }
            })
            .catch((err) => {
              console.error(`${err.name}: ${err.message}`);
            });
        })
        .catch((err) => {
          /* handle the error */
        });

    },
    //获取设备摄像头列表
    checkCameras() {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          /* use the stream */
          navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
              this.cameras = devices.filter(device => device.kind == "videoinput");
              if (this.cameras && this.cameras.length != 0) {
                this.cameraId = this.cameras[0].deviceId;
              }
            })
            .catch((err) => {
              console.error(`${err.name}: ${err.message}`);
            });
        })
        .catch((err) => {
          /* handle the error */
        });
    },

    //1、获取本地语音流
    async publishMicrophone() {
      if (!this.rtcClient || !this.room) {
        alert("请确保已经初始化完 RTC，并已加入房间");
        return;
      }
      if (this.microphoneTrack) {
        this.room.unpublish([this.microphoneTrack]);
        this.microphoneTrack.destroy();
        this.microphoneTrack = null;
      }
      // 获取麦克风资源
      const { code, track } = await this.rtcClient.createMicrophoneAudioTrack(
        "audio",
        {
          micphoneId: this.microphoneId,
        }
      );
      console.log("即将创建本地音频流:", code, track);

      if (code !== RCRTCCode.SUCCESS) {
        this.$elementMessage({
          showClose: false,
          message: "请检查麦克风是否连接或开启访问权限",
          type: "error",
          duration: 5000,
        });
        return;
      }
      this.microphoneTrack = track;
      // 发布 音频 自己的不需要播放
      const pubRes = await this.publishLocalVoiceAndVideo(track, true);
      if (pubRes) {
        console.log("发布音频资源成功");
        //订阅当前在直播间的主播
      } else {
        console.log("发布音频资源失败");
      }
      console.log(pubRes);
    },

    // 2、获取本地视频流
    async publishCameraVideo() {
      if (!this.rtcClient || !this.room) {
        alert("请确保已经初始化完 RTC，并已加入房间");
        return;
      }
      if (this.cameraTrack) {
        this.room.unpublish([this.cameraTrack]);
        this.cameraTrack.destroy();
        this.cameraTrack = null;
      }
      // 获取摄像头资源
      const { code, track } = await this.rtcClient.createCameraVideoTrack(
        "video",
        {
          // frameRate: "FPS_30",
          // resolution: "W960_H720",
          cameraId: this.cameraId,
        }
      );
      console.log("即将创建视频流", this.cameraId, track);
      if (code !== RCRTCCode.SUCCESS) {
        if (code == RCRTCCode.GET_USER_MEDIA_FAILED) {
          this.errorDes = "请检查摄像头是否连接或开启访问权限";
          this.$elementMessage({
            showClose: false,
            message: "请检查摄像头是否连接或开启访问权限",
            type: "error",
            duration: 5000,
          });
        }
        return;
      }

      this.cameraTrack = track;
      // 发布
      const pubRes = await this.publishLocalVoiceAndVideo(track, false);
      if (pubRes) {
        console.log("发布视频资源成功");
        //订阅当前在直播间的主播
      } else {
        console.log("发布视频资源失败");
      }
    },
    //3、布本地音视频资源
    async publishLocalVoiceAndVideo(localTrack, isAudio) {
      if (!localTrack) {
        return;
      }
      const { code, liveUrl } = await this.room.publish([localTrack]);
      if (code === RCRTCCode.SUCCESS) {
        console.log("发布本地流成功");
        if (!isAudio) {
          let node = this.$refs.mainScreen;
          this.playLocalVoiceAndVideoTrack(localTrack, isAudio, node);
        }
        return true;
      } else {
        alert(`发布资源失败: ${code}`);
      }
      return false;
    },
    playLocalVoiceAndVideoTrack(track, playAudio, node) {
      console.log("playTrack ==> " + track);
      if (track.isVideoTrack()) {
        console.log("播放");
        track.play(node);
        // return;
      }
      // 播放音频
      if (playAudio) {
        track.play();
      }
    },
    //1、获取共享屏幕流
    async getShareScreen() {
      if (this.isShareVideo) {
        this.$elementAlert("当前正在共享音视频，无法同时共享屏幕", "温馨提示", {
          confirmButtonText: "确定",
          callback: (action) => { },
        });
        return;
      }
      if (this.isShareing) {
        console.log("当前正在共享屏幕");
        if (this.screenShareTrack) {
          this.room.unpublish([this.screenShareTrack]);
          this.screenShareTrack.destroy();
          this.screenShareTrack = null;
        }
        if (this.localCustomTracks) {
          this.localCustomTracks.forEach((track) => {
            track.destroy();
          });
          this.localCustomTracks = null;
        }
        this.isShareing = false;
        return;
      }

      //1、创建屏幕共享音视频流
      let response = await this.rtcClient.createScreenWithAudioTracks(
        "screenshare",
        {
          frameRate: "FPS_30",
          resolution: "W1920_H1080",
        }
      );
      const { code, tracks } = response;

      if (code !== RCRTCCode.SUCCESS) {
        console.warn("捕获屏幕共享流失败 ->", code);
        if (code == 53019) {
          this.$elementMessage({
            showClose: false,
            message: "该浏览器不支持屏幕共享,建议使用Chrome浏览器",
            type: "error",
          });
        } else {
          this.$elementMessage({
            showClose: false,
            message: "共享音视频异常，请重新尝试",
            type: "error",
          });
        }
        return;
      }
      console.warn("捕获屏幕共享流成功 ->", tracks);
      let that = this;
      const { code: pubCode } = await this.room.publish(tracks);
      if (pubCode !== RCRTCCode.SUCCESS) {
        console.warn("11111发布屏幕共享流失败 ->", pubCode);
        tracks.forEach((track) => {
          track.destroy();
        });
        this.isShareing = false;
      } else {
        console.warn("111111发布屏幕共享流成功 ->", pubCode);
        this.isShareing = true;
        tracks.forEach((track) => {
          if (track.isVideoTrack()) {
            that.screenShareTrack = track;
            // 监听屏幕共享流结束事件, RCLocalTrack 可以通过 import { RCLocalTrack } from '@rongcloud/plugin-rtc' 获取
            track.on(RCLocalTrack.EVENT_LOCAL_TRACK_END, (track) => {
              console.warn("111111屏幕共享流结束坚挺 ->");
              // 取消发布屏幕共享流，room 为当前加入的房间实例
              if (that.screenShareTrack) {
                that.room.unpublish([this.screenShareTrack]);
                that.screenShareTrack.destroy();
                that.screenShareTrack = null;
              }
              if (that.localCustomTracks) {
                that.room.unpublish([that.localCustomTracks]);
                that.localCustomTracks.forEach((track) => {
                  track.destroy();
                });
                that.localCustomTracks = null;
              }
              that.isShareing = false;
            });
          }
          if (track.isAudioTrack()) {
            that.localCustomTracks = [track];
          }
        });
      }
    },
    //注册主播模式的监听
    async registAnchorEventListener(room, code, tracks) {
      let _self = this;
      if (code !== RCRTCCode.SUCCESS) {
        if (code == RCRTCCode.GET_USER_MEDIA_FAILED) {
          _self.errorDes = "设备未连接摄像头或者摄像头权限不被允许";
          _self.$elementAlert(
            "设备未连接摄像头或者摄像头权限不被允许",
            "温馨提示",
            {
              confirmButtonText: "确定",
              callback: (action) => {
                _self.getOutLiveRoom();
              },
            }
          );
        }
        if (code == RCRTCCode.REPERT_JOIN_ROOM) {
          _self.$elementAlert(
            "加入房间出错，请刷新当前页面或者返回",
            "温馨提示",
            {
              confirmButtonText: "确定",
              callback: (action) => { },
            }
          );
        }
        console.log("join room failed:", code);
        return;
      }
      this.room = room;
      //发布本地的音视频流 如果当前选中了摄像头 否则自动获取第一个
      if (_self.cameraId && _self.cameraId.length != 0) {
        this.publishCameraVideo();
      } else {
        this.checkCameras();
      }
      if (_self.microphoneId && _self.microphoneId.length != 0) {
        this.publishMicrophone();
      } else {
        this.checkMicrophones();
      }

      //订阅当前所有的远程主播
      // 注册房间事件监听器，重复注册时，仅最后一次注册有效
      room.registerRoomEventListener({
        /**
         * 当 RTCPeerConnection 连接被异常关闭时触发，此时需业务层重新加入房间并重新发布、订阅资源。
         * 引起连接异常中断的原因包括但不限于：电脑休眠、浏览器页面长期后台运行等
         * @since 5.1.5
         */
        onRTCPeerConnectionCloseByException() {
          _self.joinLivingRoom();
        },
        /**
         * 当本端被剔出房间
         * @description 被踢出房间可能是由于服务端超出一定时间未能收到 rtcPing 消息，所以认为己方离线。
         * 另一种可能是己方 rtcPing 失败次数超出上限，故而主动断线
         * @param byServer
         * 当值为 false 时，说明本端 rtcPing 超时
         * 当值为 true 时，说明本端收到被踢出房间通知
         */
        onKickOff(byServer) {
          console.log("onKickedByServer", byServer);
          _self.$elementAlert(
            "您被挤出当前房间，请检查你的账号是否在其他端登录?",
            "温馨提示",
            {
              confirmButtonText: "确定",
              callback: (action) => {
                _self.clearAction();
              },
            }
          );
        },
        /**
         * 房间内用户发布资源
         * @param tracks 新发布的音轨与视轨数据列表，包含新发布的 RCRemoteAudioTrack 与 RCRemoteVideoTrack 实例
         */
        async onTrackPublish(tracks) {
          console.log("有人发布了资源，即将进行订阅");
          console.log(tracks);
          _self.subscribe(tracks);
        },
        /**
         * 房间用户取消发布资源
         * @param tracks 被取消发布的音轨与视轨数据列表
         * @description 当资源被取消发布时，SDK 内部会取消对相关资源的订阅，业务层仅需处理 UI 业务
         */
        onTrackUnpublish(tracks) {
          console.log("有人取消资源");
          let streamIds = tracks.map(function (item) {
            return item.getStreamId();
          });
          console.log(streamIds);
          console.log(_self.remoteTracks);
          _self.remoteTracks = _self.remoteTracks.filter(function (item) {
            let streamId = item.getStreamId();
            //不包含
            return streamIds.indexOf(streamId) <= -1;
          });
        },
        /**
         * track 可播放
         */
        onTrackReady(track) {
          console.log("可以播放onTrackReady");
          _self.remoteTracks.push(track);
        },
        /**
         * 主播和观众切换身份通知
         */
        onSwitchRole(userId, role) {
          console.log("用户身份切换");
          console.log(role);
          if (role == 2) {
            //getUserId
            _self.remoteTracks = _self.remoteTracks.filter(function (item) {
              let uid = item.getUserId();
              //不包含
              return uid != userId;
            });
          }
        },
        onUserLeave(userIds) {
          console.log("用户退出", userIds);
          _self.remoteTracks = _self.remoteTracks.filter(function (item) {
            let uid = item.getUserId();
            //不包含
            return userIds.indexOf(uid) == -1;
          });
        },
      });
      this.subscribe(tracks);
    },
    //更新布局
    updateLayoutUI() {
      if (this.localCustomTracks && this.localCustomTracks.length != 0) {
        let node = this.$refs.mainScreen;
        let that = this;
        this.localCustomTracks.forEach((track) => {
          if (track.isVideoTrack()) {
            track.play(node);
            console.log("用户111111", that.cameraTrack);
            if (that.cameraTrack) {
              that.cameraTrack.mute();
              console.log("用户222222", that.cameraTrack);
            }
          } else {
            track.play();
          }
        });
      } else {
        let node = this.$refs.mainScreen;
        if (this.cameraTrack) {
          //本地流
          if (this.cameraTrack.isLocalMuted()) {
            this.cameraTrack.mute();
          } else {
            this.cameraTrack.unmute();
            this.cameraTrack.play(node);
          }
        }
      }

      this.removeVideoElement();
      this.appendVideoElement();
    },
    appendVideoElement() {
      if (this.isBoarder) {
        let right_node = document.getElementById("rightNode");
        console.log("开始添加元素", right_node);
        if (!right_node) {
          return;
        }
        if (this.screenShareTrack) {
          let node = this.getNextNode(right_node);
          this.screenShareTrack.play(node);
        }
        let that = this;
        if (this.remoteTracks) {
          this.remoteTracks.forEach((track) => {
            if (track.isVideoTrack()) {
              let node = that.getNextNode(right_node);
              track.play(node);
            } else {
              track.play();
            }
          });
        }
      } else {
        let node = this.$refs.mainScreen;
        let that = this;
        if (this.remoteTracks) {
          this.remoteTracks.forEach((track) => {
            if (track.isVideoTrack()) {
              track.play(node);
            } else {
              if (that.muted) {
                this.$elementMessage({
                  showClose: false,
                  message: "设备麦克风已关闭",
                  type: "success",
                });
                track.mute();
              } else {
                track.unmute();
              }
            }
          });
        }
      }
    },
    getNextNode(parentNode) {
      var node = document.createElement("div");
      node.className = "sub-screen-small";
      var videoNode = document.createElement("video");
      videoNode.setAttribute("width", "100%");
      videoNode.setAttribute("height", "100%");
      videoNode.setAttribute("autoplay", "true");
      videoNode.style.backgroundColor = "#484848";
      videoNode.style.objectFit = "cover";
      node.appendChild(videoNode);
      parentNode.appendChild(node);
      return videoNode;
    },
    removeVideoElement() {
      let right_node = document.getElementById("rightNode");
      if (!right_node) {
        return;
      }
      let chidlren = right_node.childNodes;
      console.log("子元素", right_node.childNodes);

      if (chidlren) {
        console.log("准备移除:");
        console.log(chidlren.length);
        for (var i = chidlren.length - 1; i >= 0; i--) {
          // 一定要倒序，正序是删不干净的，可自行尝试
          console.log("正在移除", i, chidlren[i]);
          right_node.removeChild(chidlren[i]);
        }
      }
    },
    //1、观众端加入
    async audienceJoin() {
      let roomId = this.roomId;
      let _self = this;
      let result = await this.rtcClient.joinLivingRoomAsAudience(
        roomId,
        RCLivingType.AUDIO_VIDEO
      );
      console.log(result);
      const { room, RTCTracks, MCUTracks, CDNUris, userIds, code } = result;
      this.registAudienceEventListener(room, code, MCUTracks);
    },
    //注册观众模式的监听
    registAudienceEventListener(room, code, MCUTracks) {
      console.log(code);
      console.log(room);
      console.log(MCUTracks);
      if (code !== RCRTCCode.SUCCESS) {
        console.log("join room as audience failed:", code);
        return;
      }
      let _self = this;
      this.room = room;
      console.log("开始订阅远端合流");
      room.registerRoomEventListener({
        /*
         * 房间内直播合流资源发布
         * @param track RCRemoteTrack 类实例
         * * 房间类型以第一个加入房间用户设置的直播类型为准
         * * 房间直播类型为 RCLivingType.AUDIO_VIDEO ，tracks 包含 RCRemoteAudioTrack 与 RCRemoteVideoTrack 实例
         * * 直播类型为 RCLivingType.AUDIO ， tracks 仅包含 RCRemoteAudioTrack 实例
         * * 触发时机: 主播发布资源后
         */
        onTrackPublish(tracks) {
          console.log("观众端：房间内直播合流资源发布");
          console.log(tracks);
          _self.remoteTracks = tracks;
          _self.subscribe(tracks);
        },
        /*
         * 房间内直播合流资源取消发布
         * @param track RCRemoteTrack 类实例
         * * 触发时机: 全部主播退出房间（因资源为多个主播发布的合流资源，单个主播取消发布不会触发）
         */
        onTrackUnpublish(tracks) {
          console.log("观众端：房间内直播合流资源取消发布");
          console.log(tracks);
          _self.remoteTracks = [];
        },
        /*
         * 房间内主播资源发布
         * @param track RCRemoteTrack 类实例
         * * 触发时机: 主播发布资源后
         */
        onAnchorTrackPublish(tracks) {
          console.log("观众端：房间内主播资源发布");
        },
        /*
         * 房间内主播资源取消发布
         * @param track RCRemoteTrack 类实例
         * * 触发时机: 主播取消发布资源后
         */
        onAnchorTrackUnpublish(tracks) {
          console.log("观众端：房间内主播资源取消发布");
        },
        /**
         * 订阅的音视频流通道已建立, track 已可以进行播放
         * @param track RCRemoteTrack 类实例
         */
        onTrackReady(track) {
          console.log("观众端准备播放", track.isAudioTrack());
          _self.muted = true
          _self.remoteTracks.push(track);
        },
      });
      this.subscribe(MCUTracks);
    },
    //订阅资源
    async subscribe(tracks) {
      if (tracks.length == 0) {
        return;
      }
      const { code } = await this.room.subscribe(tracks);
      if (code !== RCRTCCode.SUCCESS) {
        alert(`网络异常，请尝试重新进入!: ${code}`);
      } else {
        console.log("订阅远端资源成功");

      }
    },
    //取消订阅
    async unsubscribe(tracks, leaveRoom) {
      console.log(this.room);
      console.log(this.rtcClient);

      if (tracks.length != 0 && this.room) {
        const { code } = await this.room.unsubscribe(tracks);
      }
      if (leaveRoom) {
        this.leaveRoom();
      }
    },
    //点击静音按钮事件
    microphoneAction() {
      console.log(this.microphoneTrack);
      if (this.microphoneTrack.isLocalMuted()) {
        this.microphoneTrack.unmute();
      } else {
        this.microphoneTrack.mute();
      }
      console.log(this.localCustomTracks);
    },
    cameraAction() {
      if (this.localCustomTracks && this.localCustomTracks.length != 0) {
        let array = this.localCustomTracks.filter((item) => {
          return item.isVideoTrack();
        });
        if (array.length != 0) {
          this.$elementMessage({
            showClose: false,
            message: "当前正在进行视频共享，无法进行此操作",
            type: "error",
          });
          return;
        }
      }
      if (this.cameraTrack != null) {
        if (this.cameraTrack.isLocalMuted()) {
          this.cameraTrack.unmute();
          let node = this.$refs.mainScreen;
          this.cameraTrack.play(node);
        } else {
          this.cameraTrack.mute();
        }
      }
    },
    clearAction() {
      //取消发布 销毁直播流
      if (this.cameraTrack) {
        this.cameraTrack.destroy();
        this.cameraTrack = null;
      }
      if (this.microphoneTrack) {
        this.microphoneTrack.destroy();
        this.microphoneTrack = null;
      }
      if (this.screenShareTrack) {
        this.screenShareTrack.destroy();
        this.screenShareTrack = null;
        this.isShareing = false;
      }
      if (this.localCustomTracks && this.localCustomTracks.length != 0) {
        this.localCustomTracks.forEach((element) => {
          element.destroy();
        });
        this.localCustomTracks = null;
        this.isShareVideo = false;
      }
      disconnectSocket();
      if (this.room) {
        this.room.registerRoomEventListener(null);
      }
      RongIMLib.disconnect();
      this.$router.back();
    },
    /**
     * 退出直播
     */
    async getOutLiveRoom() {
      if (this.isBoarder) {
        //先取消订阅
        let sources = [];
        //取消发布 销毁直播流
        if (this.cameraTrack) {
          sources.push(this.cameraTrack);
        }
        if (this.microphoneTrack) {
          sources.push(this.microphoneTrack);
        }
        if (this.screenShareTrack) {
          sources.push(this.screenShareTrack);
        }
        if (this.localCustomTracks && this.localCustomTracks.length != 0) {
          sources = sources.concat(this.localCustomTracks);
        }
        console.log('即将释放多路流媒体', sources)
        //说明本地都没发布流
        if (sources.length == 0) {
          //离开房间
          this.unsubscribe(this.remoteTracks, true);
        } else {
          const { code } = await this.room.unpublish(sources);
          if (code !== RCRTCCode.SUCCESS) {
            console.warn("取消发布失败 ->", code);
          } else {
            console.warn("取消发布成功 ->", code);
            //取消发布 销毁直播流
            if (this.cameraTrack) {
              this.cameraTrack.clear()
              this.cameraTrack.destroy();
              this.cameraTrack = null;
            }
            if (this.microphoneTrack) {
              this.microphoneTrack.clear()
              this.microphoneTrack.destroy();
              this.microphoneTrack = null;
            }
            if (this.screenShareTrack) {
              this.screenShareTrack.clear()
              this.screenShareTrack.destroy();
              this.screenShareTrack = null;
              this.isShareing = false;
            }
            if (this.localCustomTracks && this.localCustomTracks.length != 0) {
              this.localCustomTracks.forEach((element) => {
                element.destroy();
              });
              this.localCustomTracks = null;
              this.isShareVideo = false;
            }
            this.unsubscribe(this.remoteTracks, true);
          }
        }
      } else {
        this.unsubscribe(this.remoteTracks, true);
      }
    },
    //退出直播间
    async leaveRoom() {
      disconnectSocket();
      if (this.room) {
        this.room.registerRoomEventListener(null);
      }
      if (this.rtcClient) {
        if (this.isBoarder) {
          //主播端
          const { code } = await this.rtcClient.leaveRoom(this.room);
          if (code !== RCRTCCode.SUCCESS) {
            console.log("退出房间失败");
          } else {
            console.log("退出房间成功");
            RongIMLib.disconnect();
            this.$router.back();
          }
        } else {
          // 观众端
          const { code } = await this.rtcClient.leaveLivingRoomAsAudience(
            this.room
          );
          if (code !== RCRTCCode.SUCCESS) {
            console.log("退出房间失败", code);
          } else {
            console.log("退出房间成功");
            RongIMLib.disconnect();
            this.$router.back();
          }
        }
      } else {
        RongIMLib.disconnect();
        this.$router.back();
      }
    },
  },
};
</script>

<style scope lang="scss">
.msg-content {
  color: "#333333";
  margin: 0px 10px 0px 5px;
  padding: 5px 10px;
  text-align: left;
}

.chat-massage-editor {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}

.chat-editor {
  height: 150px;
  right: 0;
  width: 30%;
  bottom: 0px;
  position: fixed;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-editor :deep(.el-textarea_inner) {
  background-color: #ffffff;
  box-shadow: 0 0 0 0;
}

.chat-massage-list {
  height: calc(100% - 220px);
}

.default-max {
  background-color: red;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.badge-box {
  background-color: #ddf6ff;
  border: 1px solid #0e9bd2;
  font-size: 12px;
  color: #0e9bd2;
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 500;
  margin-left: 5px;
}

.badge-box:first-child {
  margin: 0;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  $h: calc(100% - 98px);
  height: $h;
  background-color: #ffffff;
}

.main-screen-big {
  width: 100%;
  $h: calc(100% - 98px);
  // height: $h;
  background-color: rgb(56, 56, 56);
  position: relative;
}

.main-screen-small {
  $h: calc(100vh - 98px);
  $average: calc(#{$h} / 3);
  $ratio: 16 / 9;
  $w1: calc(#{$average} * #{$ratio});
  width: calc(100vw - #{$w1});
  // flex: 1;
  // height: 100%;
  height: $h;
  background-color: rgb(56, 56, 56);
}

.right-screen {
  $h: calc(100vh - 98px);
  // height: $h;
  $average: calc(#{$h} / 3);
  $ratio: 16 / 9;
  $w1: calc(#{$average} * #{$ratio});
  width: $w1;
  background-color: rgb(56, 56, 56);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
}

.sub-screen-small {
  $h: calc(100vh - 98px);
  $average: calc(#{$h} / 3);
  $ratio: 16 / 9;
  $w1: calc(#{$average} * #{$ratio});
  height: $average;
  width: $w1;
  background-color: rgb(56, 56, 56);
}

.member-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 10px 15px;
}
</style>
